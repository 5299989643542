import { ReactElement, ReactNode, useState } from "react";
import { SessionContext, SessionContextType } from "../../context/session/SessionContext";
import SessionService from "../../services/sessions/sessions.service";

interface SessionProviderProps {
  children: ReactNode;
  sessionService: SessionService;
}

const SessionProvider = (props: SessionProviderProps): ReactElement => {
  const { sessionService, children } = props;
  const [knownToken, setKnownToken] = useState(sessionService?.getSessionToken());

  const handleSetTokenOnUrl = (token: string) => {
    setKnownToken(token);
    sessionService?.storeSessionToken(token);
  };

  const sessionToken = sessionService.getSessionToken();
  if (sessionToken !== knownToken) {
    setKnownToken(sessionToken);
  }

  const context = {
    sessionService,
    sessionToken: knownToken,
    setTokenOnUrl: (token: string) => handleSetTokenOnUrl(token),
  } as SessionContextType;

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <SessionContext.Provider value={context}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
