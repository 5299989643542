import { createContext, useContext } from "react";
import TransactionCart from "../../models/transactionCart/transactionCart";

export type TransactionCartContextType = TransactionCart | undefined;

export const TransactionCartContext = createContext<TransactionCartContextType | undefined>(undefined);

export const useTransactionCart = (): TransactionCart => {
  const context = useContext(TransactionCartContext);
  if (context === undefined) {
    throw new Error("useTransactionCart must be used within a TransactionCartProvider");
  }
  return context;
};
