import { ReactElement } from "react";
import { Col } from "react-bootstrap";
import CompanyHeaderInfo from "../../models/companySettings/CompanyHeaderInfo";
import { fullAddressToStringFromCompanyHeaderInfo } from "../../utils/customerFormatter";
import "./Header.scss";
import DCHeaderSize from "../../common/types/dcHeaderSize";
import DCHeader from "../../components/DCHeader/DCHeader";
import "./CompanyInfoHeader.scss";

/*
 * Display any optional website url
 */
const Website = ({ website }: { website?: string }): ReactElement =>
  website ? (
    <div className='company-website justify-content-lg-around'>
      <a href={website} target='_blank' rel='noreferrer'>
        {website}
      </a>
    </div>
  ) : (
    <></>
  );
Website.defaultProps = { website: undefined };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CompanyInfoHeader = ({ companyHeaderInfo }: { companyHeaderInfo: CompanyHeaderInfo }): ReactElement => {
  const fullAddress = fullAddressToStringFromCompanyHeaderInfo(companyHeaderInfo);
  const companyName = companyHeaderInfo.name ?? "";
  return (
    <DCHeader title={companyName} logoUrl={companyHeaderInfo.logoUrl} size={DCHeaderSize.Large} url={companyHeaderInfo.website}>
      <Col className='company-header'>
        <div className='company-name'>{companyName}</div>
        <div className='company-address'>{fullAddress}</div>
        <Website website={companyHeaderInfo.website} />
      </Col>
    </DCHeader>
  );
};
export default CompanyInfoHeader;
