import { createContext, useContext } from "react";
import IntentToPayService from "../../models/intentToPay/intentToPayService";

export type IntentToPayServiceContextType = IntentToPayService | undefined;

export const IntentToPayServiceContext = createContext<IntentToPayServiceContextType | undefined>(undefined);

export const useIntentToPayService = (): IntentToPayService => {
  const context = useContext(IntentToPayServiceContext);
  if (context === undefined) {
    throw new Error("useIntentToPayService must be used within a IntentToPayServiceProvider");
  }
  return context;
};
