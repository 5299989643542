import { ReactElement, ReactNode, useState } from "react";
import { CustomerDetailsExtended } from "../../models/customers/customerDetailsExtended";
import { CustomerDetailsContext, CustomerDetailsContextType } from "./CustomerDetailsContext";

interface CustomerDetailsProviderProps {
  children: ReactNode;
}

/*
 * Customer details
 */
const CustomerDetailsProvider = (props: CustomerDetailsProviderProps): ReactElement => {
  const { children } = props;
  const [customerDetails, setCustomerDetails] = useState<CustomerDetailsExtended>();

  //  const setMethod = (newCustomerDetails:CustomerDetails) => setCustomerDetails(newCustomerDetails);

  const context: CustomerDetailsContextType = [
    customerDetails,
    (newCustomerDetails: CustomerDetailsExtended) => setCustomerDetails(newCustomerDetails),
  ];
  return <CustomerDetailsContext.Provider value={context}>{children}</CustomerDetailsContext.Provider>;
};

export default CustomerDetailsProvider;
