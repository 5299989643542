import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationCommonEN from "./assets/translations/en/common-en.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: ["common"],
    resources: {
      en: {
        common: translationCommonEN
      }
    },
    defaultNS: "common",
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export const tCommon = { ns: "common" };
export const tForms = { ns: "forms" };
export default i18n;
