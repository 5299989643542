class FetchResponseException extends Error {
  public response: Response;

  constructor(response: Response, message?: string | undefined) {
    super(message);
    this.name = "FetchError";
    this.response = response;
  }
}

export default FetchResponseException;
