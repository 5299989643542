const RouteConsts = {
  Home: "/",
  RequestLink: "/pr",
  Error: "/error",
  Health: "/health",
  Preview: "/preview",
  Test: "/test",
  PaymentProcessing: "/payment-processing",
  PaymentWaiting: "/payment-waiting-to-complete",
  PaymentReserved: "/payment-reserved",
  PaymentComplete: "/payment-complete",
  PaymentScheduled: "/payment-scheduled",
  PaymentBankDebitScheduled: "/payment-bankdebit-scheduled",
  OpayoEmbeddedCard: "/opayo/card-payment-frame",
  OpayoEmbedded3dSecure: "/opayo/3d-secure-frame",
  Opayo3dSecureRedirect: "/opayo/3d-secure-redirect",
  StripePaymentRedirect: "/payment-processing/stripe",
  Session: "/session",
  SessionExpired: "/session-expired",
  DirectDebitSetupStatus: "/direct-debit-setup",
};

export default RouteConsts;
