import IntentToPayRequestProviderData from "../../paymentProcess/intentToPayRequestProviderData";
import IntentToPayResultProviderData from "../../paymentProcess/intentToPayResultProviderData";
import { PaymentProviderCredentials } from "../paymentProviderConfiguration";

export const TrueLayerProviderName = "TrueLayer";

export interface TrueLayerCredentials extends PaymentProviderCredentials {
  clientId: string;
  clientSecret?: string;
  useSandbox: boolean;
}

export interface TrueLayerIntentOfPaymentRequestProviderData extends IntentToPayRequestProviderData {
  // The url TrueLayer should redirect back to when it has completed the payment
  redirectUrl: string;
}

export interface TrueLayerIntentOfPaymentResultProviderData extends IntentToPayResultProviderData {
  // Id provided by TrueLayer
  Id: string;

  // The status details in text
  status: string;

  // The url to redirect to (on the Opayo website)
  nextUrl: string;
}

/*
 * Constructor for TrueLayerIntentOfPaymentRequestProviderData
 */
export const initTrueLayerIntentOfPaymentRequestProviderData = (
  redirectUrl: string
): TrueLayerIntentOfPaymentRequestProviderData => {
  const data: TrueLayerIntentOfPaymentRequestProviderData = { providerName: TrueLayerProviderName, redirectUrl };
  return data;
};
