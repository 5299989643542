import BaseNoSessionPayThemPayerApi from "./baseNoSessionPayThemPayerApi";
import { FeatureFlagResponse } from "../../../models/featureFlags/featureFlagResponse";

/*
 * Access to the info endpoints
 */
class InfoApi extends BaseNoSessionPayThemPayerApi {
  private endPoint = "info";

  /*
   * Returns the feature flags for the payer website
   */
  public async getFeatureFlags(): Promise<FeatureFlagResponse> {
    const result = await this.get<FeatureFlagResponse>(`${this.endPoint}/feature-flags`);
    return result;
  }
}
export default InfoApi;
