import BasePayThemPayerApi from "./basePayThemPayerApi";
import { IntentToPayTypeRequest } from "../../../models/paymentProcess/intentToPayRequest";
import { IntentToPayTypeResult } from "../../../models/paymentProcess/intentToPayResult";

import IntentToPayRequestProviderData from "../../../models/paymentProcess/intentToPayRequestProviderData";
import IntentToPayResultProviderData from "../../../models/paymentProcess/intentToPayResultProviderData";
import CompletePayRequestProviderData from "../../../models/paymentProcess/completePayRequestProviderData";
import CompletePayResultProviderData from "../../../models/paymentProcess/completePayResultProviderData";
import PaymentTransactionSummary from "../../../models/paymentTransactions/paymentTransactionSummary";
import { CompletePayTypeRequest } from "../../../models/paymentProcess/completePayRequest";
import { CompletePayTypeResult } from "../../../models/paymentProcess/completePayResult";
import { UpdateIntentToPayRequest } from "../../../models/paymentProcess/updateIntentToPayRequest";
import { PaymentJourneyStatus } from "../../../models/paymentProcess/paymentJourneyStatus";

class PaymentProcessApi extends BasePayThemPayerApi {
  private endPoint = "payment-process";

  /*
   * Registers the payment intent with the server
   */
  public async registerPaymentIntent<
    TProviderDataRequest extends IntentToPayRequestProviderData,
    TProviderDataResult extends IntentToPayResultProviderData
  >(intentToPayRequest: IntentToPayTypeRequest<TProviderDataRequest>): Promise<IntentToPayTypeResult<TProviderDataResult>> {
    const url = `${this.endPoint}/register-payment-intent`;
    const result = await this.postWithResponse<
      IntentToPayTypeRequest<TProviderDataRequest>,
      IntentToPayTypeResult<TProviderDataResult>
    >(url, intentToPayRequest);
    return result;
  }

  public async updatePaymentIntent(updateIntentToPayRequest: UpdateIntentToPayRequest) {
    const url = `${this.endPoint}/update-payment-intent`;
    await this.post(url, updateIntentToPayRequest);
  }

  /*
   * Informs PayThem to complete the payment
   */
  public async completePayProcess<
    TProviderDataRequest extends CompletePayRequestProviderData,
    TProviderDataResult extends CompletePayResultProviderData
  >(completePayRequest: CompletePayTypeRequest<TProviderDataRequest>): Promise<CompletePayTypeResult<TProviderDataResult>> {
    const url = `${this.endPoint}/complete-payment-intent`;
    const result = await this.postWithResponse<
      CompletePayTypeRequest<TProviderDataRequest>,
      CompletePayTypeResult<TProviderDataResult>
    >(url, completePayRequest);
    return result;
  }

  /*
   * Cancels the currently registered payment intent
   */
  public async cancelPaymentIntent(customerTransactionId: string): Promise<void> {
    const url = `${this.endPoint}/cancel-payment-intent`;
    await this.post(url, { customerTransactionId });
  }

  /*
   * Returns the status of the current payment process for this session
   */
  public async getPaymentStatus(): Promise<PaymentJourneyStatus> {
    const url = `${this.endPoint}/status`;
    const result = await this.get<PaymentJourneyStatus>(url);
    return result;
  }

  /*
   * Returns any payment transaction summary
   */
  public async getPaymentTransactionSummary(): Promise<PaymentTransactionSummary> {
    const url = `${this.endPoint}/payment-transaction`;
    const result = await this.get<PaymentTransactionSummary>(url);
    return result;
  }

  /*
   * Informs PayThem to continue processing this in the background and send an email to the user
   */
  public async continueProcess(): Promise<void> {
    const url = `${this.endPoint}/continue-processing`;
    await this.post(url);
  }
}

export default PaymentProcessApi;
