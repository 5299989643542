import jwt_decode from "jwt-decode";
import RouteConsts from "../layout/RouteConsts";
import { SessionJwt } from "../models/sessions/sessionJwt";

/*
 * Returns the decoded session token
 */
export const getDecodedSessionToken = (token: string): SessionJwt => {
  const decoded = jwt_decode<SessionJwt>(token);
  return decoded;
};

/*
 * Builds the path to the original payment request link from the session token
 */
export const getRequestLinkPath = (token: string): string => {
  const decoded = getDecodedSessionToken(token);
  const url = `${RouteConsts.RequestLink}/${decoded.rlid}`;
  return url;
};
