import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import DCHeaderSize from "../../common/types/dcHeaderSize";
import "./DCHeader.scss";

interface Props {
  logoUrl?: string;
  title: string;
  url?: string;
  children?: React.ReactNode;
  size?: DCHeaderSize;
  className?: string;
}

const DCHeader: React.FC<Props> = ({ logoUrl, url, title, children, size, className }) => {
  const href = url ?? "#home";

  return (
    <Navbar sticky='top' className={`${className} ${size === DCHeaderSize.Large ? "large" : ""}`}>
      <Container>
        <Navbar.Brand href={href} target='_blank'>
          {logoUrl && <img src={logoUrl} className='header-logo pe-3' alt={`${title} logo`} />}
          {!logoUrl && <>{title}</>}
        </Navbar.Brand>
        <Nav className='justify-content-end flex-grow-1'>{children}</Nav>
      </Container>
    </Navbar>
  );
};

DCHeader.defaultProps = {
  logoUrl: undefined,
  url: undefined,
  size: DCHeaderSize.Default,
  className: undefined,
  children: undefined,
};

export default DCHeader;
