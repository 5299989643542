import PaymentProcessApi from "../../common/api/payThemPayerApi/paymentProcessApi";
import { CustomerDetails } from "../../models/customers/customerDetails";
import IntentToPayRequestProviderData from "../../models/paymentProcess/intentToPayRequestProviderData";
import IntentToPayResultProviderData from "../../models/paymentProcess/intentToPayResultProviderData";
import { IntentToPayTypeRequest } from "../../models/paymentProcess/intentToPayRequest";
import { IntentToPayTypeResult } from "../../models/paymentProcess/intentToPayResult";
import { PaymentIntentItem } from "../../models/paymentProcess/paymentIntentItem";
import PaymentTransactionSummary from "../../models/paymentTransactions/paymentTransactionSummary";
import LocationProvider from "../../common/providers/locationProvider";
import SessionService from "../sessions/sessions.service";
import { getPaymentRedirectUrl } from "../../helpers/environmentVariableHelpers";
import { addBackslash } from "../../utils/urlUtils";
import sessionsConstants from "../sessions/sessions.constants";
import CompletePayRequestProviderData from "../../models/paymentProcess/completePayRequestProviderData";
import CompletePayResultProviderData from "../../models/paymentProcess/completePayResultProviderData";
import { CompletePayTypeResult } from "../../models/paymentProcess/completePayResult";
import { CompletePayTypeRequest } from "../../models/paymentProcess/completePayRequest";
import { UpdateIntentToPayRequest } from "../../models/paymentProcess/updateIntentToPayRequest";
import { PaymentJourneyStatus } from "../../models/paymentProcess/paymentJourneyStatus";
import { PaymentMethodType } from "../../models/paymentProviders/paymentMethodType";
import { PaymentChargeType } from "../../models/paymentProcess/paymentChargeType";

/*
 * Register an intent to pay
 */
export const registerPaymentIntent = async <
  TProviderDataRequest extends IntentToPayRequestProviderData,
  TProviderDataResult extends IntentToPayResultProviderData
>(
  customerDetails: CustomerDetails,
  paymentProviderConfigurationId: string,
  paymentIntentItems: PaymentIntentItem[],
  paymentMethodType: PaymentMethodType,
  providerData?: TProviderDataRequest,
  storedPaymentMethodId?: string,
  errorHandler?: Function | undefined
): Promise<IntentToPayTypeResult<TProviderDataResult>> => {
  const intentRequest: IntentToPayTypeRequest<TProviderDataRequest> = {
    customerDetails,
    paymentProviderConfigurationId,
    paymentMethodType,
    paymentIntentItems,
    providerData,
    storedPaymentMethodId,
    paymentChargeType: PaymentChargeType.Manual,
  };

  const api = new PaymentProcessApi();
  const result = await api.registerPaymentIntent<TProviderDataRequest, TProviderDataResult>(intentRequest).catch((error) => {
    if (errorHandler) {
      errorHandler(error);
    }
    throw error;
  });
  return result;
};

/*
 * Register an intent to pay
 */
export const updatePaymentIntent = async (
  paymentProviderConfigurationId: string,
  errorHandler?: Function | undefined
): Promise<void> => {
  const intentRequest: UpdateIntentToPayRequest = {
    paymentProviderConfigurationId,
  };
  const api = new PaymentProcessApi();
  const result = await api.updatePaymentIntent(intentRequest).catch((error) => {
    if (errorHandler) {
      errorHandler(error);
    }
    throw error;
  });
  return result;
};

/*
 * Cancels the currently registered payment intent
 */
export const cancelPaymentIntent = async (
  customerTransactionId: string,
  errorHandler?: Function | undefined
): Promise<void> => {
  const api = new PaymentProcessApi();
  await api.cancelPaymentIntent(customerTransactionId).catch((error) => {
    if (errorHandler) {
      errorHandler(error);
    }
    throw error;
  });
};

/*
 * Completes the payment request
 */
export const completePayProcess = async <
  TProviderDataRequest extends CompletePayRequestProviderData,
  TProviderDataResult extends CompletePayResultProviderData
>(
  request: CompletePayTypeRequest<TProviderDataRequest>,
  errorHandler?: Function | undefined
): Promise<CompletePayTypeResult<TProviderDataResult>> => {
  const api = new PaymentProcessApi();
  const result = await api.completePayProcess<TProviderDataRequest, TProviderDataResult>(request).catch((error) => {
    if (errorHandler) {
      errorHandler(error);
    }
    throw error;
  });
  return result;
};

// /*
//  * Completes the payment request
//  */
// export const completePayProcessWithProviderData = async <
//   TProviderDataRequest extends CompletePayRequestProviderData,
//   TProviderDataResult extends CompletePayResultProviderData
// >(
//   providerData?: TProviderDataRequest,

//   errorHandler?: Function | undefined
// ): Promise<CompletePayTypeResult<TProviderDataResult>> => {
//   const request: CompletePayTypeRequest<TProviderDataRequest> = {
//     providerData,
//   };

//   const api = new PaymentProcessApi();
//   const result = await api.completePayProcess<TProviderDataRequest, TProviderDataResult>(request).catch((error) => {
//     if (errorHandler) {
//       errorHandler(error);
//     }
//     throw error;
//   });
//   return result;
// };

/*
 * Returns the status of the current payment process for this session
 */
export const getPaymentStatus = async (errorHandler?: Function | undefined): Promise<PaymentJourneyStatus> => {
  const api = new PaymentProcessApi();
  const result = await api.getPaymentStatus().catch((error) => {
    if (errorHandler) {
      errorHandler(error);
    }
    throw error;
  });
  return result;
};

/*
 * Informs PayThem to continue processing this in the background and send an email to the user
 */
export const activateContinueProcessing = async (errorHandler?: Function | undefined): Promise<void> => {
  const api = new PaymentProcessApi();
  await api.continueProcess().catch((error) => {
    if (errorHandler) {
      errorHandler(error);
    }
    throw error;
  });
};

/*
 * Gets the payment transaction summary
 */
export const getPaymentTransactionSummary = async (errorHandler?: Function | undefined): Promise<PaymentTransactionSummary> => {
  const api = new PaymentProcessApi();
  const result = await api.getPaymentTransactionSummary().catch((error) => {
    if (errorHandler) {
      errorHandler(error);
    }
    throw error;
  });
  return result;
};

const locationProvider = new LocationProvider();
const sessionService = new SessionService(locationProvider);

/*
 * Builds the payment redirect url by either query or route parameters
 */
export const buildPaymentRedirectUrl = (providerName: string, useQueryParams: boolean, addSlash: boolean = true): string => {
  const sessionToken = sessionService.getSessionToken();
  let url = `${addBackslash(getPaymentRedirectUrl())}${providerName.toLowerCase()}`;
  if (useQueryParams) {
    const urlObject = new URL(url);
    urlObject.searchParams.set(sessionsConstants.STORAGETOKENKEY, sessionToken);
    url = urlObject.toString();
  } else {
    url = `${url}/${sessionToken}${addSlash ? "/" : ""}`;
  }
  return url;
};
