// Type guard wrapper
export class TypeGuard {
  static isNumber(item: number | unknown): item is number {
    return typeof item === "number";
  }

  static isString(item: string | unknown): item is string {
    return typeof item === "string";
  }

  static isBigInt(item: bigint | unknown): item is bigint {
    return typeof item === "bigint";
  }

  static isBoolean(item: boolean | unknown): item is boolean {
    return typeof item === "boolean";
  }
}
export default TypeGuard;
