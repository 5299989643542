import { AxiosInstance } from "axios";
import CompanyHeaderInfo from "../../../models/companySettings/CompanyHeaderInfo";
import { BaseApi } from "../baseApi";
import { getPayThemPayerClient } from "./payThemPayerClient.instance";
import PayThemPayerApiClientType from "./payThemPayerApiClientType";

class CompanySettingsPreviewApi extends BaseApi {
  private endPoint = "company-settings";

  // eslint-disable-next-line class-methods-use-this
  protected getInstance(): AxiosInstance {
    const instance = getPayThemPayerClient(PayThemPayerApiClientType.WithPreview);
    return instance;
  }

  /*
   * Returns the company settings
   */
  public async getCompanyHeaderInfo(): Promise<CompanyHeaderInfo> {
    const result = await this.get<CompanyHeaderInfo>(`${this.endPoint}/header-info`);
    return result;
  }
}

export default CompanySettingsPreviewApi;
