enum HeaderNames {
  JavaScriptEnabled = "x-javascript-enabled",
  JavaEnabled = "x-java-enabled",
  Language = "x-language",
  TimeZoneOffsetInMinutes = "x-time-zone-offset",
  ScreenHeight = "x-screen-height",
  ScreenWidth = "x-screen-width",
  ColorDepth = "x-color-depth",
  SessionToken = "Session-Token",
  PreviewToken = "Preview-Token",
}

export default HeaderNames;
