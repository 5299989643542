import { Dispatch, createContext, useContext } from "react";
import { StripePublicKeys } from "../../models/paymentProviders/stripe/stripePublicKeys";

export type StripeKeysContextType = [StripePublicKeys | undefined, Dispatch<StripePublicKeys>];

export const StripeKeysContext = createContext<StripeKeysContextType | undefined>(undefined);

export const useStripeKeys = (): StripeKeysContextType => {
  const context = useContext(StripeKeysContext);
  if (context === undefined) {
    throw new Error("useStripeKeys must be used within a StripeKeysProvider");
  }
  return context;
};
