/* eslint-disable class-methods-use-this */
import { AxiosInstance } from "axios";
import { BaseApi } from "../baseApi";
import { getPayThemPayerClient } from "./payThemPayerClient.instance";
import PayThemPayerApiClientType from "./payThemPayerApiClientType";

abstract class BaseNoSessionPayThemPayerApi extends BaseApi {
  protected getInstance(): AxiosInstance {
    const instance = getPayThemPayerClient(PayThemPayerApiClientType.NoSession);
    return instance;
  }
}
export default BaseNoSessionPayThemPayerApi;
