import getProductInfo from "../common/productInfo";

const SubTitleSeperator = " | ";

/*
 * Returns the page title
 */
export const getHtmlPageTitle = (): string => getProductInfo().productName;

/*
 * Returns the short name version of the page title
 */
export const getHtmlPageShortTitle = (): string => getProductInfo().productShortName;

/*
 * Returns the page title
 */
export const getHtmlSubPageTitle = (subTitle1: string, subTitle2?: string): string => {
  let title = getHtmlPageShortTitle();

  if (!subTitle1 || subTitle1 === "") return title;

  title += SubTitleSeperator + subTitle1;

  if (!subTitle2 || subTitle2 === "") return title;

  title += SubTitleSeperator + subTitle2;

  return title;
};
