class AbortControllerProvider {
  private abortController: AbortController;

  constructor() {
    this.abortController = new AbortController();
  }

  public abort(): void {
    this.abortController.abort();
  }

  public signal(): AbortSignal | undefined {
    return this.abortController.signal;
  }
}
export default AbortControllerProvider;
