/*
 * Returns true of the development features environment setting is set to true
 */
export const areDevelopmentFeaturesAllowed = (): boolean => process.env.REACT_APP_ALLOW_DEV_FEATURES === "true";

/*
 * Returns true if in development mode
 */
export const isDevelopmentMode = (): boolean => areDevelopmentFeaturesAllowed() || process.env.NODE_ENV === "development";

/*
 * Returns the payer (this) site url for PayThem
 */
export const getPayThemPayerSiteUrl = (): string => process.env.REACT_APP_PAYTHEM_PAYERSITE_URL || "";

/*
 * Returns the admin site url for PayThem
 */
export const getPayThemAdminSiteUrl = (): string => process.env.REACT_APP_PATHEM_ADMINSITE_URL || "";

/*
 * Returns the product site url for PayThem
 */
export const getPayThemProductSiteUrl = (): string => process.env.REACT_APP_PRODUCTSITE_URL || "";

/*
 * Returns the standard redirect url for a payment provider to call
 */
export const getPaymentRedirectUrl = (): string => process.env.REACT_APP_PAYMENT_REDIRECT_URL || "";

/*
 * Returns the Opayo redirect url
 */
export const getOpayoRedirectUrl = (): string => process.env.REACT_APP_OPAYO_REDIRECT_URL || "";

/*
 * Returns the Stripe redirect url
 */
export const getStripeRedirectUrl = (): string => process.env.REACT_APP_STRIPE_REDIRECT_URL || "";

/*
 * Returns the current site url
 */
export const getCurrentSiteUrl = (): string => process.env.REACT_APP_USER_REDIRECT_URL || "";
