import { PaymentCalculationResult } from "../models/paymentDetails/paymentCalculationResult";
/*
 * Finds a PaymentCalculationResult or throws an exception
 */
export const findValidPaymentCalculationResultFromTransactionId = (
  transactionId: string,
  paymentCalculationResults?: PaymentCalculationResult[]
): PaymentCalculationResult => {
  const paymentCalculation = paymentCalculationResults?.find((x) => x.id === transactionId);
  if (!paymentCalculation) throw Error("Payment calculation for transaciton was not found");
  return paymentCalculation;
};

/*
 * Calculates the maximum amount that can be paid on the transaction
 */
export const calculateMaximumAmountToPay = (paymentCalculationResult: PaymentCalculationResult): number => {
  // Can't edit the amount so return the total amount to charge
  if (!paymentCalculationResult.amountIsEditable) {
    return paymentCalculationResult.totalAmountToCharge;
  }

  // Check that the maximum amount is not greater than the total amount that can be paid
  let maximum = paymentCalculationResult.maximumAmountToPay || undefined;
  if (!maximum || maximum > paymentCalculationResult.totalAmountToCharge) {
    maximum = paymentCalculationResult.totalAmountToCharge;
  }
  return maximum;
};

/*
 * Calculates the minimum amount that can be paid on the transaction
 */
export const calculateMinimumAmountToPay = (paymentCalculationResult: PaymentCalculationResult): number => {
  // Can't edit the amount so return the total amount to charge
  if (!paymentCalculationResult.amountIsEditable) {
    return paymentCalculationResult.totalAmountToCharge;
  }

  // Check that the minimum amount is not less than zero
  let minimum = paymentCalculationResult.minimumAmountToPay || undefined;
  if (!minimum || minimum < 0) {
    minimum = 0;
  }

  // Check that the minimum amount is not greater than the total amount that can be paid
  if (minimum > paymentCalculationResult.totalAmountToCharge) {
    minimum = paymentCalculationResult.totalAmountToCharge;
  }
  return minimum;
};
