import { ReactElement, ReactNode, useState } from "react";
import { StripeKeysContext, StripeKeysContextType } from "./StripeKeysContext";
import { StripePublicKeys } from "../../models/paymentProviders/stripe/stripePublicKeys";

interface StripeKeysProviderProps {
  children: ReactNode;
}

/*
 * Stripe public keys provider
 */
const StripeKeysProvider = ({ children }: StripeKeysProviderProps): ReactElement => {
  const [stripePublicKeys, setStripePublicKeys] = useState<StripePublicKeys>();
  const context: StripeKeysContextType = [
    stripePublicKeys,
    (newStripePublicKeys: StripePublicKeys) => setStripePublicKeys(newStripePublicKeys),
  ];
  return <StripeKeysContext.Provider value={context}>{children}</StripeKeysContext.Provider>;
};

export default StripeKeysProvider;
