import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const instrumentationKey = process.env.REACT_APP_APPINSIGHTS_KEY;
const ai = new ApplicationInsights({
  config: {
    instrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

if (instrumentationKey) {
  ai.loadAppInsights();
}

export const { appInsights } = ai;
export { reactPlugin };
