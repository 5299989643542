import Lottie from "lottie-react";
import { ReactElement } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import animationGeneralError from "../../assets/data/anim/general-error.json";
import { tCommon } from "../../i18n";

interface GeneralErrorPageProps {
  errorMessage: string | undefined | null;
}

// eslint-disable-next-line arrow-body-style
const GeneralErrorPage = ({ errorMessage }: GeneralErrorPageProps): ReactElement => {
  const { t } = useTranslation(tCommon.ns, { keyPrefix: "errors.generalErrors" });
  return (
    <Container fluid style={{ paddingTop: "0", marginTop: "0" }}>
      <Row>
        <Col className='p-0'>
          <div className='d-flex flex-column align-items-center' style={{ paddingTop: "0", marginTop: "0" }}>
            <h4 className='error-message mt-4'>{errorMessage}</h4>
            <div style={{ width: "55%", marginTop: "0px", marginBottom: "0px", zIndex: "0" }}>
              <Lottie animationData={animationGeneralError} title={t("title")} />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default GeneralErrorPage;
