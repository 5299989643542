import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import getUserLocale from "get-user-locale";
import { TypeGuard } from "./typeGuards";

/*
 * Default text to display for dateTimeOrString
 */
const DefaultBlankText = "N/A";
const DefaultShortDateFormat = "PP";
const DefaultShortDateTimeFormat = "PP pp";
const DefaultLongDateFormat = "PPPP";

// Default to USA
const defaultLocale = enUS;
let userLocale = defaultLocale;

export const initialiseDateFormatter = async (): Promise<Locale> => {
  const localeString = getUserLocale();
  try {
    const result = await import(`date-fns/locale/${localeString}/index.js`);
    userLocale = result.default;
  } catch {
    userLocale = defaultLocale;
  }
  return userLocale;
};

export const getCurrentUserLocale = (): Locale => userLocale;

export enum DateOutputType {
  ShortDate,
  ShortDateTime,
  LongDate,
}

/*
 * Formats the given date using the given string format
 */
export const formatDate = (date: Date, formatString: string): string => format(new Date(date), formatString);

/*
 * Returns the short date format to use
 */
export const getShortDateFormat = (): string => DefaultShortDateFormat;

/*
 * Returns the short date/time format to use
 */
export const getShortDateTimeFormat = (): string => DefaultShortDateTimeFormat;

/*
 * Returns the long date format to use
 */
export const getLongDateFormat = (): string => DefaultLongDateFormat;

/*
 * Returns short date format string
 */
export const toShortDate = (date: Date): string => format(new Date(date), getShortDateFormat(), { locale: userLocale });

/*
 * Returns short date/time format string
 */
export const toShortDateTime = (date: Date): string => format(new Date(date), getShortDateTimeFormat(), { locale: userLocale });

/*
 * Returns short date format string
 */
export const toShortDateFromString = (dateAsString: string): string => toShortDate(new Date(dateAsString));

/*
 * Returns short date/time format string
 */
export const toShortDateTimeFromString = (dateAsString: string): string => toShortDateTime(new Date(dateAsString));

/*
 * Returns long date format string
 */
export const toLongDate = (date: Date): string => format(new Date(date), getLongDateFormat(), { locale: userLocale });

/*
 * Returns long date/time format string
 */
export const toLongDateFromString = (dateAsString: string): string => toLongDate(new Date(dateAsString));

/*
 * Returns format string based on type
 */
const toOutputString = (date: Date | string, outputType: DateOutputType): string => {
  let output: string;

  switch (outputType) {
    case DateOutputType.ShortDate:
      output = TypeGuard.isString(date) ? toShortDateFromString(date) : toShortDate(date);
      break;
    case DateOutputType.ShortDateTime:
      output = TypeGuard.isString(date) ? toShortDateTimeFromString(date) : toShortDateTime(date);
      break;
    case DateOutputType.LongDate:
      output = TypeGuard.isString(date) ? toLongDateFromString(date) : toLongDate(date);
      break;
    default:
      throw new Error("Unknown DateOutputType");
  }
  return output;
};

/*
 * Displays a date or given string if date is undefined
 */
export const dateTimeOrString = (
  date: Date | string | undefined | null,
  outputType: DateOutputType = DateOutputType.ShortDateTime,
  blankText = DefaultBlankText
): string => {
  if (!date) {
    return blankText;
  }
  return toOutputString(date, outputType);
};
