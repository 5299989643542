import { createContext, useContext } from "react";
import SessionService from "../../services/sessions/sessions.service";

export type SessionContextProps = {
  // The session service
  sessionService: SessionService;
  sessionToken: string;
  setTokenOnUrl: (token: string) => void;
};

export type SessionContextType = SessionContextProps;

export const SessionContext = createContext<SessionContextProps | undefined>(undefined);

export const useSession = (): SessionContextProps => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};
