import { ReactElement, ReactNode, useState } from "react";
import { CustomerDetails } from "../../models/customers/customerDetails";
import IntentToPayService from "../../models/intentToPay/intentToPayService";
import IntentToPayRequestProviderData from "../../models/paymentProcess/intentToPayRequestProviderData";
import { IntentToPayResult, IntentToPayTypeResult } from "../../models/paymentProcess/intentToPayResult";
import IntentToPayResultProviderData from "../../models/paymentProcess/intentToPayResultProviderData";
import { PaymentIntentItem } from "../../models/paymentProcess/paymentIntentItem";
import { cancelPaymentIntent, registerPaymentIntent } from "../../services/paymentProcess/paymentProcess.service";
import { IntentToPayServiceContext } from "./IntentToPayContext";
import { PaymentMethodType } from "../../models/paymentProviders/paymentMethodType";

interface IntentToPayServiceProviderProps {
  children: ReactNode;
}

/*
 * Transaction cart
 */
const IntentToPayServiceProvider = (props: IntentToPayServiceProviderProps): ReactElement => {
  const { children } = props;
  const [intentToPayResult, setIntentToPayResult] = useState<IntentToPayResult>();

  const getProviderData = <T extends IntentToPayRequestProviderData>() => intentToPayResult?.providerData as T;

  const localCancelPaymentIntent = async (): Promise<void> => {
    if (intentToPayResult) {
      const id = intentToPayResult.customerTransactionId;
      setIntentToPayResult(undefined);
      cancelPaymentIntent(id);
    }
  };

  const localRegisterPaymentIntent = async <T extends IntentToPayResultProviderData>(
    customerDetails: CustomerDetails,
    paymentProviderConfigurationId: string,
    paymentIntentItems: PaymentIntentItem[],
    paymentMethodType: PaymentMethodType,
    providerData?: IntentToPayRequestProviderData
  ): Promise<IntentToPayTypeResult<T>> => {
    // Cancel any current intent to pay we have
    if (intentToPayResult) {
      await localCancelPaymentIntent();
    }

    setIntentToPayResult(undefined);

    // Register the new intent to pay
    const result = await registerPaymentIntent<IntentToPayRequestProviderData, T>(
      customerDetails,
      paymentProviderConfigurationId,
      paymentIntentItems,
      paymentMethodType,
      providerData
    );
    setIntentToPayResult(result);
    return result;
  };

  const context: IntentToPayService = {
    intentToPayResult,
    getProviderData,
    registerPaymentIntent: localRegisterPaymentIntent,
    cancelPaymentIntent: localCancelPaymentIntent,
    clearPaymentIntent: () => setIntentToPayResult(undefined),
  };

  return <IntentToPayServiceContext.Provider value={context}>{children}</IntentToPayServiceContext.Provider>;
};

export default IntentToPayServiceProvider;
