import lang from "i18next";
import getApiUrl from "../../common/api/payThemPayerApi/apiUrl";
import HttpStatusCode from "../../common/httpStatusCode";
import LocationProvider from "../../common/providers/locationProvider";
import FetchResponseException from "../../infrastructure/exceptions/FetchResponseException";
import { getDecodedSessionToken } from "../../utils/jwtUtils";
import sessionsConstants from "./sessions.constants";

const DefaultRecordType = "PaymentRequest";
const ContentType = "application/json";

class SessionService {
  static endPoint = "sessions";

  isRegisteringToken: boolean = false;

  location: LocationProvider;

  constructor(locationProvider: LocationProvider) {
    this.location = locationProvider;
  }

  /*
   * Registers a new session with the request link id
   */
  public async register(requestLinkId: string): Promise<string> {
    const sessionToken = await this.requestSessionToken(requestLinkId);
    return sessionToken;
  }

  public async complete(): Promise<void> {
    this.completeSession();
    const parameters = new URLSearchParams(this.location.search());
    parameters.delete(sessionsConstants.STORAGETOKENKEY);
    //    this.location.setSearch(parameters.toString());
  }

  private async completeSession(): Promise<void> {
    const apiUrl = `${getApiUrl()}${SessionService.endPoint}/complete`;

    const token = this.getSessionToken();
    if (!token) return;

    try {
      const response = await fetch(apiUrl, {
        headers: {
          "Content-Type": ContentType,
          "Session-Token": token,
        },
        method: "PUT",
      });
      if (response.status !== HttpStatusCode.OK) {
        throw new Error(response.statusText);
      }
    } catch (error) {
      //
    }
  }

  /*
   * Calls the api end point to request a session token
   */
  private async requestSessionToken(requestLinkId: string): Promise<string> {
    const apiUrl = `${getApiUrl()}${SessionService.endPoint}/register`;
    let response: Response;
    try {
      this.isRegisteringToken = true;
      response = await fetch(apiUrl, {
        headers: {
          "Content-Type": ContentType,
        },
        method: "POST",
        body: JSON.stringify({ requestLinkId, recordType: DefaultRecordType }),
      });
    } catch (error) {
      throw new Error(lang.t("errors.generalErrors.unableToRegisterSession"));
    } finally {
      this.isRegisteringToken = false;
    }

    if (response.status !== HttpStatusCode.OK) {
      throw new FetchResponseException(response, lang.t("errors.generalErrors.requestLinkNotFound"));
    }

    const json = await response.json();
    const sessionToken = json.accessToken;
    return sessionToken;
  }

  /*
   * Returns true if we have a session token (expired or not)
   */
  public haveSessionToken(): boolean {
    const token = this.getSessionToken();
    if (!token) {
      return false;
    }
    return true;
  }

  /*
   * Returns true if we have a valid session token
   */
  public haveNonExpiredSessionToken(): boolean {
    const token = this.getSessionToken();
    if (!token) {
      return false;
    }
    return !SessionService.checkTokenExpired(token);
  }

  /*
   * Returns the stored session token, which is a parameter on the query string
   */
  public getSessionToken(): string {
    const search = this.location.search();
    const token = new URLSearchParams(search).get(sessionsConstants.STORAGETOKENKEY);
    return token || "";
  }

  /*
   * Stores the session token on the query string
   */
  public storeSessionToken(token: string): void {
    const parameters = new URLSearchParams(this.location.search());
    parameters.set(sessionsConstants.STORAGETOKENKEY, token);
    this.location.setSearch(parameters.toString());
  }

  /*
   * Appends the given session token to the given url, to the current if none passed in
   */
  public static buildUrlWithSession(urlString: string, token: string): string {
    const url = new URL(urlString);
    url.searchParams.set(sessionsConstants.STORAGETOKENKEY, token);
    return url.toString();
  }

  /*
   * Appends the current session token to the given path
   */
  public buildPathWithSession(path: string): string {
    const token = this.getSessionToken();
    const parameters = new URLSearchParams();
    parameters.set(sessionsConstants.STORAGETOKENKEY, token);
    const newPath = `${path}?${parameters.toString()}`;
    return newPath;
  }

  /*
   * Returns true if the token has expired
   */
  public hasTokenExpired(): boolean {
    const token = this.getSessionToken();
    const result = SessionService.checkTokenExpired(token);
    return result;
  }

  private static checkTokenExpired(token: string): boolean {
    // By default, if we dont have a token it has expired
    if (!token) return true;

    const payLoad = getDecodedSessionToken(token);
    const tokenExpiresDate = new Date(0);
    tokenExpiresDate.setUTCSeconds(payLoad.exp ?? 0);
    const currentDate = new Date();
    if (currentDate > tokenExpiresDate) return true;
    return false;
  }
}

export default SessionService;
