import { ReactElement } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DraycirSpinner from "../components/spinners/DraycirSpinner";
import { tCommon } from "../i18n";
import "./LoadingPage.scss";

const waitMessages = ["waitMessage0", "waitMessage1", "waitMessage2", "waitMessage3"];

const LoadingPage = (): ReactElement => {
  const { t } = useTranslation(tCommon.ns, { keyPrefix: "waitMessages" });

  const message = waitMessages[Math.floor(Math.random() * waitMessages.length)];

  return (
    <Container className='loading-container'>
      <div className='loading-message'>
        <div>{t(message)}</div>
        <DraycirSpinner />
      </div>
    </Container>
  );
};

export default LoadingPage;
