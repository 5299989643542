import {
  AppInsightsContext,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import { ReactElement, ReactNode } from 'react';

interface AppInsightsProviderProps {
  children: ReactNode;
  reactPlugin: ReactPlugin;
}

const AppInsightsProvider = (props: AppInsightsProviderProps): ReactElement => {
  const { reactPlugin, children } = props;

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
};

export default AppInsightsProvider;
