/*
 * Converts date/time strings to JS Date objects during parsing
 */
// eslint-disable-next-line import/prefer-default-export
export function jsonParseWithDate<T>(jsonString: string): T | undefined {
  const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.{0,1}\d*))(?:Z|(\+|-)([\d|:]*))?$/;

  if (jsonString.length === 0) return undefined;

  const resultObject = JSON.parse(jsonString, (key: unknown, value: unknown) => {
    if (typeof value === "string" && reISO.exec(value)) {
      return new Date(value);
    }
    return value;
  });
  return resultObject as T;
}
