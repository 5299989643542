import { createContext, useContext } from "react";

export type PreviewTokenContextProps = {
  previewToken: string;
  setTokenOnUrl: (token: string) => void;
};

export type PreviewTokenContextType = PreviewTokenContextProps;

export const PreviewTokenContext = createContext<PreviewTokenContextProps | undefined>(undefined);

export const usePreviewToken = (): PreviewTokenContextProps => {
  const context = useContext(PreviewTokenContext);
  if (context === undefined) {
    throw new Error("usePreviewToken must be used within a PreviewTokenProvider");
  }
  return context;
};
