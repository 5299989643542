import { ReactElement, ReactNode, useState } from "react";
import {
  removeTransactionCartItemFromTransactionCart,
  addTransactionCartItemToTransactionCart,
  getTotalAmountToChargeFromTransactionCart,
  getTransactionCartItemFromTransactionCart,
} from "../../helpers/transactionCartHelper";
import TransactionCart from "../../models/transactionCart/transactionCart";
import TransactionCartItem from "../../models/transactionCart/transactionCartItem";
import { TransactionCartContext } from "./TransactionCartContext";

interface TransactionCartProviderProps {
  children: ReactNode;
}

/*
 * Transaction cart
 */
const TransactionCartProvider = (props: TransactionCartProviderProps): ReactElement => {
  const { children } = props;
  const [transactionItems, setTransactionItems] = useState<TransactionCartItem[]>([]);

  const getTransactionFromCart = (transactonId: string) =>
    getTransactionCartItemFromTransactionCart(transactionItems, transactonId);

  // Removes an existing transaction from the cart
  const removeTransactionFromCart = (transactonId: string) =>
    setTransactionItems((previousItems) => removeTransactionCartItemFromTransactionCart(previousItems, transactonId));

  // Adds a transaction to the cart
  const addTransactionToCart = (item: TransactionCartItem) =>
    setTransactionItems((previousItems) => addTransactionCartItemToTransactionCart(previousItems, item));

  // Updates a transaction in the cart
  const updateTransactionInCart = (item: TransactionCartItem) =>
    setTransactionItems((previousItems) =>
      addTransactionCartItemToTransactionCart(
        removeTransactionCartItemFromTransactionCart(previousItems, item.transactonId),
        item
      )
    );

  // Returns the total amount to charge
  const getTotalAmountToCharge = () => getTotalAmountToChargeFromTransactionCart(transactionItems);

  const getCurrency = () => (transactionItems.length > 0 ? transactionItems[0].currency : "");

  const emptyCart = () => setTransactionItems([]);

  const context: TransactionCart = {
    transactionItems,
    getTransactionFromCart,
    addTransactionToCart,
    removeTransactionFromCart,
    updateTransactionInCart,
    getTotalAmountToCharge,
    getCurrency,
    emptyCart,
  };
  return <TransactionCartContext.Provider value={context}>{children}</TransactionCartContext.Provider>;
};

export default TransactionCartProvider;
