import { ReactElement } from "react";
import Lottie from "lottie-react";
import draycirexpanding from "../../assets/data/draycir4-spinner-data.json";
import draycirshrinking from "../../assets/data/draycir5-spinner-data.json";

export type AnimationType = "expanding" | "shrinking";

interface DraycirSpinnerProps {
  width?: string;
  height?: string;
  animationType?: AnimationType;
}

/*
 * Displays a spinner
 */
const DraycirSpinner = ({ width, height, animationType }: DraycirSpinnerProps): ReactElement => {
  const data = animationType === "expanding" ? draycirexpanding : draycirshrinking;
  return <Lottie animationData={data} title='Draycir Spinner' width={width} height={height} />;
};

DraycirSpinner.defaultProps = {
  width: undefined,
  height: undefined,
  animationType: "expanding",
};

export default DraycirSpinner;
