import { ReactElement } from "react";
import CompanyInfoHeader from "./CompanyInfoHeader";
import GenericHeader from "./GenericHeader";

import useCompanyInfo from "../../context/companyInfo/CompanyInfoContext";
import "./Header.scss";

// Displays the header at the top of the screen
const Header = (): ReactElement => {
  const companyInfo = useCompanyInfo();
  return companyInfo ? <CompanyInfoHeader companyHeaderInfo={companyInfo} /> : <GenericHeader />;
};

export default Header;
