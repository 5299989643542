import { TypeGuard } from "./typeGuards";

export const toSnakeCase = (str: string): string =>
  str
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();

const toUrlFormat = (key: string, val: string): string => `${encodeURIComponent(toSnakeCase(key))}=${encodeURIComponent(val)}`;

export const toUrlEncoded = (obj: Record<string, unknown>): string =>
  Object.keys(obj)
    .map((k) => {
      const value = obj[k];
      if (TypeGuard.isString(value)) {
        return toUrlFormat(k, value);
      }
      if (TypeGuard.isNumber(value)) {
        return toUrlFormat(k, value.toString());
      }
      if (TypeGuard.isBigInt(value)) {
        return toUrlFormat(k, value.toString());
      }
      if (TypeGuard.isBoolean(value)) {
        return toUrlFormat(k, value.toString());
      }
      throw new Error("Invalid Data Type.");
    })
    .join("&");

/*
 * Adds a trailing backslash to the string is non exists
 */
export const addBackslash = (url: string): string => url.replace(/\/?$/, "/");

/*
 * Removes a trailing backslash from the string if it exists
 */
export const removeBackslash = (url: string): string => url.replace(/\\/g, "");

/*
 * Removes a trailing forward slash from the string if it exists
 */
export const removeForwardslash = (url: string): string => url.replace(/\/+$/, "");

export class UrlUtils {
  /*
   *  Get the current url
   */
  public static getUrl(): string {
    return window.location.href;
  }

  /*
   *  Get the current absolute url
   */
  public static getAbsoluteUrl(): string {
    return `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  }

  /*
   * Get the current relative url e.g. "/path1/path2"
   */
  public static getRelativeUrl(): string {
    return window.location.pathname.replace(/\/$/, "");
  }

  /*
   * Get the current domain url, e.g "https://subdomain.domain.com"
   */
  public static getAbsoluteDomainUrl(): string {
    return `${window.location.protocol}//${window.location.host}`;
  }

  /*
   * Builds a new path from the current domain
   */
  public static buildPathFromDomain(path: string): string {
    return `${window.location.origin}${path}`;
  }
}
