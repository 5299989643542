import { Dispatch, createContext, useContext } from "react";
import { CustomerDetailsExtended } from "../../models/customers/customerDetailsExtended";

export type CustomerDetailsContextType = [CustomerDetailsExtended | undefined, Dispatch<CustomerDetailsExtended>];

export const CustomerDetailsContext = createContext<CustomerDetailsContextType | undefined>(undefined);

/*
 * Context for supplying and updating the details of the customer who is paying
 */
const useCustomerDetails = (): CustomerDetailsContextType => {
  const context = useContext(CustomerDetailsContext);
  if (context === undefined) {
    throw new Error("useCustomerDetails must be used within a CustomerDetailsProvider");
  }
  return context;
};

export default useCustomerDetails;
