import { ReactElement } from "react";
// import DCHeaderSize from "../../common/types/dcHeaderSize";
// import DCHeader from "../../components/DCHeader/DCHeader";
// import { ReactComponent as SampleLogo } from "../../assets/svg/SampleLogo.svg";

const GenericHeader = (): ReactElement => (
  <></>
  // <DCHeader title="Pay Them" Logo={SampleLogo} size={DCHeaderSize.Large} />
);

export default GenericHeader;
