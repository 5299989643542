interface ProductInfo {
  productName: string;
  productShortName: string;
  supportUrl: string;
  termsOfServiceUrl: string;
  privacyPolicyUrl: string;
  appVersion: string;
  productSiteUrl: string;
}

const getProductInfo = (): ProductInfo => ({
  productName: process.env.REACT_APP_PRODUCT_NAME || "",
  productShortName: process.env.REACT_APP_PRODUCT_SHORTNAME || "",
  supportUrl: process.env.REACT_APP_SUPPORT_URL || "",
  termsOfServiceUrl: process.env.REACT_APP_TERMSOFSERVICE_URL || "",
  privacyPolicyUrl: process.env.REACT_APP_PRIVACYPOLICY_URL || "",
  appVersion: process.env.REACT_APP_APPVERSION || "",
  productSiteUrl: process.env.REACT_APP_PRODUCTSITE_URL || "",
});

export default getProductInfo;
