import { createContext, useContext } from "react";
import CompanyHeaderInfo from "../../models/companySettings/CompanyHeaderInfo";

export type CompanyInfoContextType = CompanyHeaderInfo | undefined;

export const CompanyInfoContext = createContext<CompanyInfoContextType>(undefined);

/*
 * Context for supplying the company info
 */
const useCompanyInfo = (): CompanyInfoContextType => {
  const context = useContext(CompanyInfoContext);
  return context;
};

export default useCompanyInfo;
