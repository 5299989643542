const enum PayThemPayerApiClientType {
  // Does not require any session header to be present
  NoSession = 0,

  // Requires a session header to be present
  WithSession = 1,

  // Requires a preview header to be present
  WithPreview = 2,
}

export default PayThemPayerApiClientType;
