import previewConstants from "./preview-constants";

/*
 * Returns the stored preview token, which is a parameter on the query string
 */
export const getPreviewToken = (): string => {
  const { search } = window.location;
  const token = new URLSearchParams(search).get(previewConstants.TOKENKEY);
  return token || "";
};

/*
 * Stores the preview token on the query string
 */
export const storePreviewTokenOnUrl = (token: string): void => {
  const parameters = new URLSearchParams(window.location.search);
  parameters.set(previewConstants.TOKENKEY, token);
  window.location.search = parameters.toString();
};

/*
 * Removes the preview token from the query string
 */
export const removePreviewTokenFromUrl = (): void => {
  const parameters = new URLSearchParams(window.location.search);
  if (parameters.has(previewConstants.TOKENKEY)) {
    parameters.delete(previewConstants.TOKENKEY);
    window.location.search = parameters.toString();
  }
};
