import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import PayThemFooterLogo from "../../assets/svg/PayThemFooterLogo.svg";
import { tCommon } from "../../i18n";
import "./Footer.scss";
import { isRunningInIframe } from "../../utils/httpUtilities";

const keyPrefix = "footer";

const Footer = (): ReactElement => {
  const { t } = useTranslation(tCommon.ns, { keyPrefix });

  if (isRunningInIframe()) return <></>;
  return (
    <div className='dc-footer gap-3'>
      <div>{t("poweredBy")}</div>
      <a href='https://www.paythem.co'>
        <img src={PayThemFooterLogo} className='footer-logo' alt='PayThem Logo' />
      </a>
      <div>{t("aDraycirProduct")}</div>
    </div>
  );
};

export default Footer;
