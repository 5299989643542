/* eslint-disable no-param-reassign */
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import RouteConsts from "../../../layout/RouteConsts";

import SessionService from "../../../services/sessions/sessions.service";
import { jsonParseWithDate } from "../../../utils/jsonUtils";
import AbortControllerProvider from "../../providers/abortControllerProvider";
import LocationProvider from "../../providers/locationProvider";
import getApiUrl from "./apiUrl";
import HeaderNames from "./headerNames";
import PayThemPayerApiClientType from "./payThemPayerApiClientType";
import { getRequestLinkPath } from "../../../utils/jwtUtils";
import { SessionExpiredRedirectParamName } from "../../../views/SessionExpired/sessionExpiredPageConts";
import { getPreviewToken } from "../../../services/preview/preview-service";

const locationProvider = new LocationProvider();

const sessionService = new SessionService(locationProvider);

let payThemPayerClientNoSessionInstance: AxiosInstance | null = null;
let payThemPayerClientWithSessionInstance: AxiosInstance | null = null;
let payThemPayerClientWithPreviewInstance: AxiosInstance | null = null;

const abortControllerInstance = new AbortControllerProvider();

const createAxiosInstance = (): AxiosInstance =>
  axios.create({
    baseURL: getApiUrl(),
    signal: abortControllerInstance.signal(),
  });

/*
 * Creates the interceptor function for the axios instance
 */
const createInterceptorFunction =
  (clientType: PayThemPayerApiClientType) =>
  (config: AxiosRequestConfig): AxiosRequestConfig<any> => {
    // Allow cookie assign in request header
    config.withCredentials = false;

    if (!config.signal) {
      config.signal = abortControllerInstance.signal();
    }

    // Replace json conversion with our own
    config.transformResponse = (response) => {
      if (typeof response === "string") return jsonParseWithDate(response);
      return response;
    };

    // We want to throw an error and redirect to session expired page if the session has expired
    if (sessionService.haveSessionToken() && sessionService.hasTokenExpired()) {
      abortControllerInstance.abort();

      // Redirect to the session expired page with the request link path as a parameter
      const token = sessionService.getSessionToken();
      const requestLinkPath = getRequestLinkPath(token);
      const parameters = new URLSearchParams();
      if (requestLinkPath) parameters.set(SessionExpiredRedirectParamName, requestLinkPath);
      locationProvider.replace(`${RouteConsts.SessionExpired}?${parameters.toString()}`);
      return config;
    }

    // Add session token to header if required
    if (clientType === PayThemPayerApiClientType.WithSession) {
      const token = sessionService.getSessionToken();
      config.headers![HeaderNames.SessionToken] = token;
    }

    // Add session token to header if required
    if (clientType === PayThemPayerApiClientType.WithSession) {
      const token = sessionService.getSessionToken();
      config.headers![HeaderNames.SessionToken] = token;
    }

    // Add preview token to header if required
    if (clientType === PayThemPayerApiClientType.WithPreview) {
      const token = getPreviewToken();
      config.headers![HeaderNames.PreviewToken] = token;
    }

    // Collect browser information
    // We might use this for Strong Customer Authentication

    // We can assume it true since it uses javascript to send it to our server; can't we?
    config.headers![HeaderNames.JavaScriptEnabled] = "true";

    // window.navigator.javaEnabled() is deprecated and always returns false
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/javaEnabled
    config.headers![HeaderNames.JavaEnabled] = "false";
    config.headers![HeaderNames.ColorDepth] = `${window.screen.colorDepth}`;
    config.headers![HeaderNames.ScreenHeight] = `${window.screen.height}`;
    config.headers![HeaderNames.ScreenWidth] = `${window.screen.width}`;
    config.headers![HeaderNames.Language] = `${window.navigator.language}`;
    config.headers![HeaderNames.TimeZoneOffsetInMinutes] = `${new Date().getTimezoneOffset()}`;

    return config;
  };

const interceptorForErrorHandling = (error: unknown) => {
  if (!abortControllerInstance.signal()?.aborted) Promise.reject(error);
};

/*
 * Returns the PayThem payer api client instance
 */
export const getPayThemPayerClient = (clientType: PayThemPayerApiClientType): AxiosInstance => {
  let paythemInstance: AxiosInstance;

  switch (clientType) {
    case PayThemPayerApiClientType.NoSession:
      if (payThemPayerClientNoSessionInstance === null) {
        payThemPayerClientNoSessionInstance = createAxiosInstance();
      }
      paythemInstance = payThemPayerClientNoSessionInstance;
      break;

    case PayThemPayerApiClientType.WithSession:
      if (payThemPayerClientWithSessionInstance === null) {
        payThemPayerClientWithSessionInstance = createAxiosInstance();
      }
      paythemInstance = payThemPayerClientWithSessionInstance;
      break;

    case PayThemPayerApiClientType.WithPreview:
      if (payThemPayerClientWithPreviewInstance === null) {
        payThemPayerClientWithPreviewInstance = createAxiosInstance();
      }
      paythemInstance = payThemPayerClientWithPreviewInstance;
      break;

    default:
      throw new Error("Invalid PayThemApiClientType");
  }

  const interceptorFunc = createInterceptorFunction(clientType);
  paythemInstance.interceptors.request.use(interceptorFunc, interceptorForErrorHandling);
  return paythemInstance;
};

/*
 * Clears down the PayThem client instance
 */
export const resetPayThemPayerClient = (): void => {
  payThemPayerClientWithSessionInstance = null;
  payThemPayerClientWithPreviewInstance = null;
};
