import axios, { AxiosError } from "axios";
import { ProblemDetails } from "../common/problemDetails";
import HttpStatusCode from "../common/httpStatusCode";

export const handledErrorCodes: number[] = [];

const handleApplicationError = (error: unknown, errorHandler?: Function | undefined): void => {
  // Any stack errors need to redirect
  if (!axios.isAxiosError(error) && errorHandler) {
    errorHandler(error);
    return;
  }

  // Some Axios errors need to redirect
  const object = error as AxiosError;
  const statusCode = object.response?.status;
  const errorDetails = object.response?.data as unknown as ProblemDetails;
  const shouldHandle =
    statusCode === HttpStatusCode.Unauthorized ||
    statusCode === HttpStatusCode.BadGateway ||
    statusCode === HttpStatusCode.ServiceUnavailable ||
    statusCode === HttpStatusCode.InternalServerError ||
    (statusCode === HttpStatusCode.Forbidden && handledErrorCodes.includes(errorDetails.errorCode));

  if (shouldHandle && errorHandler) {
    errorHandler(error);
    return;
  }

  // Rethrow application error like BadRequest so the app can try/catch
  // to handle specific error instead of redirect
  throw error;
};

export default handleApplicationError;
