import { PaymentCalculationResult } from "../models/paymentDetails/paymentCalculationResult";
import { TransactionAllocationInfo } from "../models/paymentRequests/paymentRequest";
import TransactionCartItem from "../models/transactionCart/transactionCartItem";
import { TransactionSummary } from "../models/transactions/transactionSummary";
import { calculateMaximumAmountToPay } from "./paymentCalculationHelper";

/*
 * Creates the transaction cart item from the transaction and settlement builder
 */
export const createTransactionCartItemFromTransaction = (
  transaction: TransactionSummary,
  transactionAllocationInfo: TransactionAllocationInfo,
  paymentCalculationResult: PaymentCalculationResult
): TransactionCartItem => {
  const maximumAmountToPay = calculateMaximumAmountToPay(paymentCalculationResult);

  const cartItem: TransactionCartItem = {
    transactonId: transaction.id,
    currency: transaction.currency,
    originalAmount: transaction.transactionAmount,
    totalAmountToCharge: maximumAmountToPay,
    discountedAmount: paymentCalculationResult.discountedAmount,
    fees: paymentCalculationResult.fees,
    settlementOfferId: paymentCalculationResult.settlementOfferId,
    settlementOffers: paymentCalculationResult.settlementOffers,
    latePayments: paymentCalculationResult.latePayments,
    transactionAllocationInfo,
    isValid: true, // by default we assume the transaction is valid
  };
  return cartItem;
};

export const createTransactionCartItemFromPaymentCalculationResult = (
  paymentCalculationResult: PaymentCalculationResult
): TransactionCartItem => {
  const maximumAmountToPay = calculateMaximumAmountToPay(paymentCalculationResult);

  const cartItem: TransactionCartItem = {
    transactonId: paymentCalculationResult.id,
    currency: paymentCalculationResult.currency,
    originalAmount: paymentCalculationResult.amountToPay,
    totalAmountToCharge: maximumAmountToPay,
    discountedAmount: paymentCalculationResult.discountedAmount,
    fees: paymentCalculationResult.fees,
    settlementOfferId: paymentCalculationResult.settlementOfferId,
    settlementOffers: paymentCalculationResult.settlementOffers,
    latePayments: paymentCalculationResult.latePayments,
    transactionAllocationInfo: undefined,
    isValid: true, // by default we assume the transaction is valid
  };
  return cartItem;
};

/*
 * Returns the transaction cart item from the transaction cart if it exists
 */
export const getTransactionCartItemFromTransactionCart = (
  cart: TransactionCartItem[],
  transactonId: string
): TransactionCartItem | undefined => cart.find((x) => x.transactonId === transactonId);

/*
 * Removes a transaction from the cart checkout if it exists
 */
export const removeTransactionCartItemFromTransactionCart = (
  cart: TransactionCartItem[],
  transactonId: string
): TransactionCartItem[] => {
  const newCart = cart.filter((x) => x.transactonId !== transactonId);
  return newCart;
};

/*
 * Adds a new item to the transaction cart
 */
export const addTransactionCartItemToTransactionCart = (
  cart: TransactionCartItem[],
  item: TransactionCartItem
): TransactionCartItem[] => {
  if (!cart.every((x) => x.currency === item.currency)) throw Error("All transactions must be of the same currency");

  const newItems = cart.filter((x) => x.transactonId !== item.transactonId);
  return [...newItems, item];
};

/*
 * Returns the total amount to charge from the transaction cart
 */
export const getTotalAmountToChargeFromTransactionCart = (cart: TransactionCartItem[]): number => {
  const amount = cart.reduce((sum, x) => {
    // The transaction is not valid, so we ignore it
    if (!x.isValid) {
      return sum;
    }

    return sum + x.totalAmountToCharge;
  }, 0);

  return amount;
};
