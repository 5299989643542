export enum PaymentChargeType {
  // Use details that have been manually entered
  Manual = "Manual",
  // Use token details that have been previously stored
  Token = "Token",
}

export const PaymentChargeTypeMap = new Map<PaymentChargeType, string>([
  [PaymentChargeType.Manual, "Manual"],
  [PaymentChargeType.Token, "Token"],
]);
