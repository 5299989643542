import { FeatureFlags } from "flagged";
import InfoApi from "../common/api/payThemPayerApi/infoApi";

/*
 * Returns the settings for the feature flags
 */
// eslint-disable-next-line import/prefer-default-export
export const getFeatureFlagsSettingsAsync = async (): Promise<FeatureFlags> => {
  const infoApi = new InfoApi();
  const result = await infoApi.getFeatureFlags();
  return result.featureFlags;
};
