import BasePayThemPayerApi from "./basePayThemPayerApi";
import CompanyHeaderInfo from "../../../models/companySettings/CompanyHeaderInfo";
import { IsReadyResult, ValidateConfigurationResult } from "../../../services/configuration/configurationModels";

class CompanySettingsApi extends BasePayThemPayerApi {
  private endPoint = "company-settings";

  /*
   * Returns the company settings
   */
  public async getCompanyHeaderInfo(): Promise<CompanyHeaderInfo> {
    const result = await this.get<CompanyHeaderInfo>(`${this.endPoint}/header-info`);
    return result;
  }

  /*
   * Validates the current company configuration
   */
  public async validateConfiguration(): Promise<ValidateConfigurationResult> {
    const result = await this.get<ValidateConfigurationResult>(`${this.endPoint}/validate-configuration`);
    return result;
  }

  /*
   * Validates the current company configuration returning an IsReadyResult
   */
  public async validateIsReady(): Promise<IsReadyResult> {
    const result = await this.get<IsReadyResult>(`${this.endPoint}/is-ready`);
    return result;
  }
}

export default CompanySettingsApi;
