/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, ReactNode, useEffect, useState } from "react";
import CompanyHeaderInfo from "../../models/companySettings/CompanyHeaderInfo";
import { getCompanyHeaderInfo, getPreviewCompanyHeaderInfo } from "../../services/companySettings/companySettings.service";
import { useSession } from "../session/SessionContext";
import { CompanyInfoContext } from "./CompanyInfoContext";
import { usePreviewToken } from "../../hooks/use-preview-token";

interface ProviderProps {
  children: ReactNode;
}

/*
 * Provides the company info header
 */
const CompanyInfoProvider = ({ children }: ProviderProps): ReactElement => {
  const [companyInfo, setCompanyInfo] = useState<CompanyHeaderInfo>();
  const { sessionToken } = useSession();
  const { previewToken } = usePreviewToken();

  useEffect(() => {
    const loadCompanyInfo = async () => {
      if (sessionToken) {
        const result = await getCompanyHeaderInfo();
        if (result) {
          setCompanyInfo(result);
        }
      } else if (previewToken) {
        const result = await getPreviewCompanyHeaderInfo();
        if (result) {
          setCompanyInfo(result);
        }
      }
    };
    if (!companyInfo && (sessionToken || previewToken)) {
      loadCompanyInfo();
    }
  }, [companyInfo, sessionToken, previewToken]);

  return <CompanyInfoContext.Provider value={companyInfo}>{children}</CompanyInfoContext.Provider>;
};

export default CompanyInfoProvider;
