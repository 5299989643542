import CompanySettingsPreviewApi from "../../common/api/payThemPayerApi/company-settings-preview-api";
import CompanySettingsApi from "../../common/api/payThemPayerApi/companySettingsApi";
import { ErrorHandler } from "../../common/errorHandler";
import CompanyHeaderInfo from "../../models/companySettings/CompanyHeaderInfo";

/*
 * Returns the company settings
 */
export const getCompanyHeaderInfo = async (errorHandler?: ErrorHandler): Promise<CompanyHeaderInfo | undefined> => {
  try {
    const api = new CompanySettingsApi();
    const result = await api.getCompanyHeaderInfo();
    return result;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    }
  }
  return undefined;
};

/*
 * Returns the company settings using the preview token for the company
 */
export const getPreviewCompanyHeaderInfo = async (errorHandler?: ErrorHandler): Promise<CompanyHeaderInfo | undefined> => {
  try {
    const api = new CompanySettingsPreviewApi();
    const result = await api.getCompanyHeaderInfo();
    return result;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    }
  }
  return undefined;
};
